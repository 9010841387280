import { Spinner } from 'components'
import BarChart from 'components/Chart/BarChart'
import React, { useEffect, useState } from 'react'

function ActivityReferralDeltasChart(props: any) {
    const { loading, ReferralDeltas, filter } = props
    const [activityChartData, setActivityChartData] = useState<any>(null)
    const [referralchartData, setReferralchartData] = useState<any>(null)

    useEffect(() => {
        const activityHeader = ReferralDeltas?.headers?.slice(1, 4);
        const referralHeader = ReferralDeltas?.headers?.slice(4, 7);
        const activityChartData1 = {
            labels: activityHeader,  // remove the first and last element, assuming they are not relevant for the chart
            datasets: [

                {
                    label: '',
                    data: ReferralDeltas?.activity,
                    backgroundColor: '#7cb5ec',
                }
            ]
        };
        setActivityChartData(activityChartData1);
        const ReferralChartData1 = {
            labels: referralHeader,   // remove the first and last element, assuming they are not relevant for the chart
            datasets: [

                {
                    label: '',
                    data: ReferralDeltas?.refferal,
                    backgroundColor: '#7cb5ec',
                }
            ]
        };
        setReferralchartData(ReferralChartData1)
    }, [filter,loading]);
    const chartOptions = {
        maintainAspectRatio: false,
        layout: {
            padding: 25
        },
        scales: {

            x: {
                grid: {
                    display: false
                },
            },
            y: {
                beginAtZero: true,
                ticks: {
                    maxTicksLimit: 10
                },


            }
        },
        plugins: {
            legend: {
                position: 'bottom',
            },
            datalabels: {
                color: 'white',
                display: function (context: any) {
                    return context.dataset.data[context.dataIndex] > 1; // or >= 1 or ...
                }
            }

        },barThickness: 50,
    }

    return (
        <>
            <div style={{ height: '550px', width: '700px', }}>
                <h3 style={{ textAlign: "center" }} >Activity Totals</h3>
                {activityChartData && !loading ? <BarChart chartData={activityChartData} options={chartOptions} renderLabels /> : <div><Spinner /></div>}

            </div>

            <div style={{ height: '550px', width: '700px', }}>
                <h3 style={{ textAlign: "center" }} >Referral Totals </h3>
                {referralchartData && !loading ? <BarChart chartData={referralchartData} options={chartOptions} renderLabels /> : <div><Spinner /></div>}

            </div>
        </>
    )
}

export default ActivityReferralDeltasChart


