import { useState,  } from 'react';
import { Card } from 'components';
import { useAuth } from 'hooks'
import { FilterHeader } from 'views/FilterHeader';
import { CareManagerActivities } from './CareManagerActivities';

export function CareManagerActivitiesTable() {
  const { user } = useAuth()
  const [activities, setActivities] = useState();

  const [filter, setFilter] = useState<any>({
    careManager: { id: '0', value: 'All' },
    dateRange: { id: 'All', value: 'All' },
    location: { id: user ? user?.location._id : '0', value: user ? user?.location?.location : 'All' },
    activity: { id: 'All', value: 'All' },
    status: { id: 'Open', value: 'Open' },
    carePartner: { id: 'All', value: 'All' },
    client: { id: 'All', value: 'All' },
    groupBy: { id: 'None', value: 'None' },

  });
  return (
    <div className='hejohejo'>
    <FilterHeader type="careManagement" setFilter={setFilter} filter={filter} />
    <CareManagerActivities filter={filter} setActivities={setActivities} detail />
    </div>
  );
}
