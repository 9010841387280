import { Container } from '@mui/system';
import { Layout } from 'components/Layout';
import CareManagerTabs from './CareManagerTabs';

const CareManager = (): JSX.Element => {
    return (
        <Layout>
            <div>
                <Container maxWidth={false} sx={{ paddingTop: 15, paddingLeft: '0' }}>
                    <CareManagerTabs HeaderTitle={"Care Management"} ></CareManagerTabs>
                </Container>
            </div>
        </Layout>
    );
};

export default CareManager;
