import { Spinner } from 'components';
import RadarChart from 'components/Chart/RadarChart'
import React, { useEffect, useState } from 'react'

const ActivityReferralRadarChart = (props: any) => {
  const { loading, ReferralDeltasFiler, filter } = props
  const [radarActivityData, setRadarActivityData] = useState<any>(null)
  const [radarReferralData, setRadarReferralData] = useState<any>(null)
  interface RadarDataConfig {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string;
      borderColor: string;
      pointBackgroundColor: string;
      pointBorderColor: string;
      pointHoverBackgroundColor: string;
      pointHoverBorderColor: string;
    }[];
  }

  function generateRadarData(
    data: string[][],
    headers: string[],
    labels: string[]
  ): RadarDataConfig {
    return {
      labels: headers,
      datasets: labels.map((label, index) => ({
        label,
        data: data[index].map(item => Number(item)) || [],
        fill: false,
        backgroundColor: ['#156082', '#E97132', '#196B24'][index] || '#000',
        borderColor: ['#156082', '#E97132', '#196B24'][index] || '#000',
        pointBackgroundColor: ['#156082', '#E97132', '#196B24'][index] || '#000',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: ['#156082', '#E97132', '#196B24'][index] || '#000',
      })),
    };
  }
  useEffect(() => {
    // Example usage for activity data
    // let RadarActivityData = {};
    if (ReferralDeltasFiler && ReferralDeltasFiler.activity) {
      const activityArrays = ReferralDeltasFiler?.activity.map((str: string) =>
        str.split(',').map(Number)
      );
      const RadarActivityData = generateRadarData(
        activityArrays,
        ReferralDeltasFiler.actirefertypeheader,
        ['Activity Target', 'Activity Actual', 'Activity Delta']
      );
      setRadarActivityData(RadarActivityData);

    }
    // Example usage for referral data
    // let RadarReferralData = {};
    if (ReferralDeltasFiler && ReferralDeltasFiler.refferal) {
      const refferalArrays = ReferralDeltasFiler?.refferal.map((str: string) =>
        str.split(',').map(Number)
      );
      const RadarReferralData = generateRadarData(
        refferalArrays,
        ReferralDeltasFiler.actirefertypeheader,
        ['Referral Target', 'Referral Actual', 'Referral Delta']
      );
      setRadarReferralData(RadarReferralData);
    }
  }, [filter,loading]);
  const RadarOptions = {
    scale: {
      ticks: {
        min: 0,
        max: 16,
        stepSize: 2,
        showLabelBackdrop: false,
        backdropColor: "rgba(203, 197, 11, 1)",

      },
      angleLines: {
        color: "rgba(191, 191, 191, 1)",
        lineWidth: 1
      },
      gridLines: {
        color: "rgba(191, 191, 191, 1)",
        circular: true
      },
      pointLabels: {
        fontColor: "black",
        fontSize: 15
      },
      datasets: {
        label: {
          fontColor: "white",
          fontSize: 15
        }

      }
    }
  };

  return (<>
    <div style={{ height: '550px', width: '700px', "marginTop": "7%" }} >
      <h3 style={{ textAlign: "center" }} >Activity Target vs. Actual</h3>
      {radarActivityData && !loading ? <RadarChart RadarData={radarActivityData} RadarOptions={RadarOptions} /> : <div><Spinner /></div>}
    </div>
    <div style={{ height: '550px', width: '700px', "marginTop": "7%" }} >
      <h3 style={{ textAlign: "center" }} > Referrals Target vs. Actual</h3>

      {radarReferralData && !loading ? <RadarChart RadarData={radarReferralData} RadarOptions={RadarOptions} /> : <div><Spinner /></div>}
    </div>
  </>)
}

export default ActivityReferralRadarChart