import { useState, useEffect } from 'react';
import { Card, CardHeader, Modal, NoData, Spinner } from 'components';
import API from '../../services/AxiosConfig';
import { ApplicantsCards } from './ApplicantsCards';
import { useParams } from 'react-router-dom';
import ApplicantProgressModalContent from './ApplicantProgressModalContent';

export const ApplicantProgress = ({ admin, title, URLPATH, doRefresh = null }: any) => {

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState(true);
  const [progressData, setProgressData] = useState<any>([]);
  const [stageUrl, setStageUrl] = useState('');
  const userId = useParams();
  let id = userId.id;


  if (URLPATH.includes("/prospects/") && admin === false) {
    id = userId.prospectId;
  }

  useEffect(() => {
    if (URLPATH.includes("/prospects/")) {
      setStageUrl("/prospects/progress")
    }
    else {
      setStageUrl("/applicants/progress")
    }

  }, []);
  const handleCloseModal = () => {
    setOpenModal(false);
    fetchData();
  };
  useEffect(() => {
    fetchData();
  }, [doRefresh])

  const fetchData = () => {
    setLoading(true)
    const url = admin ? `${URLPATH}` : `${URLPATH}/${id}`
    API.get(url)
      .then((rsp: any) => {
        const data = rsp.data.data;
        setProgressData(data?.progress);
        setLoading(false)
      })
      .catch((error: any) => {
        setLoading(false)
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Card>
      {loading && <Spinner />}
      <CardHeader
        title={title || "Applicant Progress"}
        setOpenModal={setOpenModal}
        expanded={expanded}
        expandable={false}
        setExpanded={setExpanded}
        addIcon={admin}
        type={''}
        setType={() => { }}
      />
      <Modal open={openModal} closeHandler={handleCloseModal} >
        <ApplicantProgressModalContent closeMe={handleCloseModal} id={id} fetchData={fetchData} URLPATH={stageUrl} />
      </Modal>
      {progressData ?
        <div className='applicant-progress-container'>
          {progressData.map((val: any, idx: number) => {
            return (
              <ApplicantsCards
                admin={admin}
                editApplicant={() => { }}
                id={id}
                cardName={val.cardName}
                items={val.cardItems}
                fetchData={fetchData}
                idx={idx}
                URLPATH={URLPATH}
              />
            );
          })}
        </div> : <NoData />}
    </Card>
  );
};


